import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  Select,
  Typography,
  Form,
  Collapse,
  Card,
} from "antd";
import { PlusOutlined, MinusCircleOutlined, CheckOutlined, CopyOutlined } from "@ant-design/icons";

import styles from "../../Pages/Home/Home.module.scss";
import handleUpdatePromptlet from "../../utils/promptlet/handleUpdatePromptlet";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import saveAsNewPromptlet from "../../utils/promptlet/saveAsNewPromptlet";

const { Option } = Select;
const { Panel } = Collapse;

const EditPromptletModal = ({
  promptletToEditModal,
  setPromptletToEditModal,
  setClientErrors,
  clientErrors,
}) => {
  const dispatch = useDispatch();
  const { isLoading: updatePromptletLoading } = useSelector(
    (state) => state?.updatePromptlet
  );
  const { isLoading: addPromptletLoading } = useSelector(
    (state) => state?.addPromptlet
  );
  const [stepsVisible, setStepsVisible] = useState(true);
  const [variablesVisible, setVariablesVisible] = useState(false);
  const [stylesVisible, setStylesVisible] = useState(false);
  const [variables, setVariables] = useState("");
  const [styleInformations, setStyleInformations] = useState("")
  const [copied, setCopied] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    if (promptletToEditModal) {
      form.setFieldsValue(promptletToEditModal);
      setVariables(promptletToEditModal?.variables);
    }
  }, [promptletToEditModal]);

  const handleUpdate = () => {
    form
      .validateFields()
      .then((values) => {
        handleUpdatePromptlet(
          { ...promptletToEditModal, ...values, variables },
          setClientErrors,
          dispatch,
          setPromptletToEditModal
        );
      })
      .catch((error) => {
        console.error("Validation Failed:", error);
      });
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        saveAsNewPromptlet(
          { ...promptletToEditModal, ...values, steps: values?.steps, variables },
          setClientErrors,
          dispatch,
          setPromptletToEditModal
        );
      })
      .catch((error) => {
        console.error("Validation Failed:", error);
      });
  };
  const handleAddStep = (add) => {
    add({
      key: "",
      title: "",
      order: "",
      persona: "",
      task: "",
      input: "",
      format: "",
      active: true,
      aiChecker: "",
      aiCheckerInstructions: "",
      aiCheckerThreshold: 10,
      humanCheckerStyle: "",
      humanCheckerPrompt: "",
      humanCheckerThreshold: 10,
    });
  };

  const handleRemoveStep = (remove, index) => {
    remove(index);
  };
  const handleVariableChange = (value) => {
    setVariables(value);
  };

  const handleStylesChange = (value) => {
    setStyleInformations(value);
  }

  return (
    <Modal
      centered
      open={promptletToEditModal?.open}
      onCancel={() =>
        setPromptletToEditModal({
          open: false,
          copy: false,
          id: "",
          title: "",
          steps: [],
          visibility: "",
          variables: "",
        })
      }
      footer={[
        <Button
          key={promptletToEditModal.copy ? "Save" : "Update"}
          type="primary"
          onClick={promptletToEditModal.copy ? handleSave : handleUpdate}
          loading={addPromptletLoading || updatePromptletLoading}
        >
          {promptletToEditModal.copy
            ? addPromptletLoading
              ? "Saving..."
              : "Save"
            : updatePromptletLoading
              ? "Updating..."
              : "Update"}
        </Button>,
      ]}
      width={900}
    >
      <div className={styles.edit_problem_container}>
        <Form
          form={form}
          initialValues={promptletToEditModal}
          layout="vertical"
        >
          <Typography.Title level={3}>
            {promptletToEditModal?.copy ? "Copy" : "Edit"} Promptlet
          </Typography.Title>
          <Row gutter={16}>
            <Col span={18}>
              <Form.Item
                label="Promptlet Title"
                name="title"
                rules={[{ required: true, message: "Please enter a title" }]}
              >
                <Input placeholder="Promptlet Title" size="large" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Visibility" name="visibility">
                <Select>
                  <Option value="private">Private</Option>
                  <Option value="sharedUnlocked">Shared (Unlocked)</Option>
                  <Option value="sharedLocked">Shared (Locked)</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {clientErrors?.title && (
            <span className={styles.error_message}>{clientErrors?.title}</span>
          )}
          <Row gutter={[0, 0]}>
            <Col xs={24} sm={8} md={3}>
              <Typography.Title
                level={4}
                style={{ border: "solid", borderColor: "lightBlue", cursor: "pointer", margin: 0, padding: 0, width: "100%" }}
                onClick={() => {
                  setStepsVisible(true);
                  setVariablesVisible(false);
                  setStylesVisible(false);
                }}
              >
                Steps
              </Typography.Title>
            </Col>
            <Col xs={24} sm={8} md={3}>
              <Typography.Title
                level={4}
                style={{
                  border: "solid",
                  borderColor: "lightblue",
                  cursor: "pointer",
                  margin: 0,
                  padding: 0,
                  width: "100%"

                }}
                onClick={() => {
                  setVariablesVisible(true);
                  setStepsVisible(false);
                  setStylesVisible(false);
                }}
              >
                Variables
              </Typography.Title>
            </Col>
            <Col xs={24} sm={8} md={8}>
              <Typography.Title
                level={4}
                style={{
                  border: "solid",
                  borderColor: "lightBlue",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setStylesVisible(true);
                  setStepsVisible(false);
                  setVariablesVisible(false);
                }}
              >
                Default Style Information
              </Typography.Title>
            </Col>
          </Row>

          {stepsVisible &&
            <div>
              <Form.List name="steps">
                {(fields, { add, remove }) => (
                  <>
                    <Collapse accordion>
                      {fields.map((field, index) => (
                        <Panel
                          header={
                            <div style={{ textAlign: "left" }}>
                              {`Step ${index + 1} | ${form.getFieldValue([
                                "steps",
                                index,
                                "key",
                              ])} | ${form.getFieldValue([
                                "steps",
                                index,
                                "title",
                              ])}`}
                            </div>
                          }
                          key={field.key}
                        >
                          <Card>
                            <Row gutter={16}>
                              {promptletToEditModal?.visibility === "private" && (
                                <Col span={24}>
                                  <Button
                                    type="text"
                                    danger
                                    icon={<MinusCircleOutlined />}
                                    onClick={() => handleRemoveStep(remove, index)}
                                    style={{ float: "right" }}
                                  >
                                    Remove Step
                                  </Button>
                                </Col>
                              )}
                              <Col span={6}>
                                <Form.Item
                                  {...field}
                                  label="Key"
                                  name={[field.name, "key"]}
                                  key={[field.key, "key"]}
                                  rules={[
                                    { required: true, message: "Key is required" },
                                  ]}
                                >
                                  <Input placeholder="Key" />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  {...field}
                                  label="Title"
                                  name={[field.name, "title"]}
                                  key={[field.key, "title"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Title is required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Title" />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  {...field}
                                  label="Order"
                                  name={[field.name, "order"]}
                                  key={[field.key, "order"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Order is required",
                                    },
                                  ]}
                                >
                                  <Input type="number" placeholder="Order" />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  {...field}
                                  label="Persona"
                                  name={[field.name, "persona"]}
                                  key={[field.key, "persona"]}
                                >
                                  <Input placeholder="Persona" />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  {...field}
                                  label="Task"
                                  name={[field.name, "task"]}
                                  key={[field.key, "task"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Task is required",
                                    },
                                  ]}
                                >
                                  <TextArea placeholder="Task" />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  {...field}
                                  label="Input"
                                  name={[field.name, "input"]}
                                  key={[field.key, "input"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Input is required",
                                    },
                                  ]}
                                >
                                  <TextArea placeholder="Input" />
                                </Form.Item>
                              </Col>

                              <Col span={12}>
                                <Form.Item
                                  {...field}
                                  label="Format"
                                  name={[field.name, "format"]}
                                  key={[field.key, "format"]}
                                >
                                  <Input placeholder="Format" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...field}
                                  label="Active"
                                  name={[field.name, "active"]}
                                >
                                  <Select placeholder="Active">
                                    <Option value={true}>True</Option>
                                    <Option value={false}>False</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  label="AI Checker"
                                  name={[field.name, "aiChecker"]}
                                >
                                  <Input placeholder="AI Checker" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  label="AI Checker Instructions"
                                  name={[field.name, "aiCheckerInstructions"]}
                                >
                                  <Input placeholder="AI Checker Instructions" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  label="AI Checker Threshold"
                                  name={[field.name, "aiCheckerThreshold"]}
                                >
                                  <Input
                                    type="number"
                                    placeholder="AI Checker Threshold"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  label="Human Checker Style"
                                  name={[field.name, "humanCheckerStyle"]}
                                >
                                  <Select
                                    placeholder="Human Checker Style"
                                    defaultValue="never"
                                  >
                                    <Option value="never">Never</Option>
                                    <Option value="always">Always</Option>
                                    <Option value="threshold">Threshold</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  label="Human Checker Prompt"
                                  name={[field.name, "humanCheckerPrompt"]}
                                >
                                  <Input placeholder="Human Checker Prompt" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  label="Human Checker Threshold"
                                  name={[field.name, "humanCheckerThreshold"]}
                                >
                                  <Input
                                    type="number"
                                    placeholder="Human Checker Threshold"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        </Panel>
                      ))}
                    </Collapse>
                    {promptletToEditModal?.visibility === "private" && (
                      <Button
                        type="dashed"
                        onClick={() => handleAddStep(add)}
                        style={{
                          width: "100%",
                          marginBottom: "20px",
                          marginTop: "20px",
                        }}
                        icon={<PlusOutlined style={{ color: "limegreen" }} />}
                      >
                        Add Step
                      </Button>
                    )}
                  </>
                )}
              </Form.List>
              {clientErrors && (
                <div className={styles.client_errors}>
                  {Object.entries(clientErrors).map(([key, error]) => (
                    <div key={key} className={styles.error_message}>
                      {error}
                    </div>
                  ))}
                </div>
              )}
            </div>}

          {variablesVisible && (
            <div style={{ marginBottom: "5px" }}>
              {/* TODO  */}
              <Typography.Text type="secondary" style={{ display: "block", marginTop: "8px", color: "limegreen" }}>

                Variables JSON String Format Example:

              </Typography.Text>
              <div style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "4px", position: "relative" }}>
                <pre style={{ color: "red" }}>

                  {`{
   "variables":[
      {
         "name":"variablename1",
         "type":"variabletype",
         "value":{
            "content":"defaultvalue"
         }
      }
   ]
}`}
                </pre>
                {copied ? <CheckOutlined style={{ position: "absolute", top: "10px", right: "10px", fontSize: "16px", color: "limegreen" }} /> : <CopyOutlined style={{ position: "absolute", top: "10px", right: "10px", fontSize: "16px", cursor: "pointer", color: "#1890ff" }}
                  onClick={() => {
                    navigator.clipboard.writeText(`{
  "variables": [
    {
      "name": "variablename1",
      "type": "variabletype",
      "value": {
        "content": "defaultvalue"
      }
    }
  ]
}`);
                    setCopied(true);
                    setTimeout(() => setCopied(false), 1500);
                  }} />}
              </div>
              <Form.Item label="Variables JSON String">
                <TextArea
                  value={variables}
                  onChange={(e) => handleVariableChange(e.target.value)}
                  placeholder={`Enter variables JSON String`}
                  rows={3}
                />
              </Form.Item>
            </div>
          )}

          {stylesVisible && (
            <Form.Item label="Style Information">
              <TextArea
                value={styleInformations}
                onChange={(e) => handleStylesChange(e.target.value)}
                placeholder={`Enter Style Informations`}
                rows={3}
              />
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default EditPromptletModal;
