import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Typography,
  Row,
  Col,
  Collapse,
} from "antd";
import { PlusOutlined, MinusCircleOutlined, CopyOutlined, CheckOutlined } from "@ant-design/icons";
import saveAsNewPromptlet from "../../utils/promptlet/saveAsNewPromptlet";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
const { Panel } = Collapse;
const { Option } = Select;

const AddPromptletModal = ({
  setPromptletToAdd,
  promptletToAdd,
  setClientErrors,
}) => {
  const dispatch = useDispatch();
  const { isLoading: addPromptletLoading } = useSelector(
    (state) => state?.addPromptlet
  );
  const [form] = Form.useForm();
  const [steps, setSteps] = useState(promptletToAdd?.steps || []);
  const [stepsVisible, setStepsVisible] = useState(true);
  const [variablesVisible, setVariablesVisible] = useState(false);
  const [variables, setVariables] = useState("");
  const [styleVisible, setStyleVisible] = useState(false);
  const [styles, setStyles] = useState("");
  const [copied, setCopied] = useState(false);
  const handleAddStep = () => {
    setSteps([...steps, {}]);
  };

  const handleRemoveStep = (index) => {
    setSteps(steps.filter((_, i) => i !== index));
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        saveAsNewPromptlet(
          { ...promptletToAdd, ...values, variables },
          setClientErrors,
          dispatch,
          setPromptletToAdd
        );
      })
      .catch((error) => {
        console.error("Validation Failed:", error);
      });
  };

  const handleVariableChange = (value) => {
    setVariables(value);
  };
  const handleStyleChange = (value) => {
    setStyles(value);
  }
  return (
    <Modal
      centered
      open={promptletToAdd?.open}
      onCancel={() => {
        setPromptletToAdd({
          open: false,
          title: "",
          steps: [],
          visibility: "sharedUnlocked",
        });
      }}
      width={900}
      footer={[
        <Button
          key="save"
          type="primary"
          onClick={handleSave}
          loading={addPromptletLoading}
        >
          {addPromptletLoading ? "Saving..." : "Save"}
        </Button>,
      ]}
    >
      <div className={styles.add_problem_container}>
        <Form form={form} initialValues={{ steps }} layout="vertical">
          <Typography.Title level={3}>Add Promptlet</Typography.Title>
          <Row gutter={16}>
            <Col span={18}>
              <Form.Item
                label="Promptlet Title"
                name="title"
                rules={[{ required: true, message: "Please enter a title" }]}
              >
                <Input
                  placeholder="Promptlet Title"
                  size="large"
                  onChange={(e) =>
                    setPromptletToAdd({
                      ...promptletToAdd,
                      title: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Visibility">
                <Select
                  value={promptletToAdd?.visibility || "sharedUnlocked"}
                  onChange={(value) => {
                    setPromptletToAdd((prevPromptlet) => ({
                      ...prevPromptlet,
                      visibility: value,
                    }));
                  }}
                >
                  <Option value="private">Private</Option>
                  <Option value="sharedUnlocked">Shared (Unlocked)</Option>
                  <Option value="sharedLocked">Shared (Locked)</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[0, 0]}>
            <Col xs={24} sm={8} md={3}>
              <Typography.Title
                level={4}
                style={{ border: "solid", borderColor: "lightBlue", cursor: "pointer", margin: 0, padding: 0, width: "100%" }}
                onClick={() => {
                  setStepsVisible(true);
                  setVariablesVisible(false);
                  setStyleVisible(false);
                }}
              >
                Steps
              </Typography.Title>
            </Col>
            <Col xs={24} sm={8} md={3}>
              <Typography.Title
                level={4}
                style={{
                  border: "solid",
                  cursor: "pointer",
                  borderColor: "lightBlue",
                  margin: 0,
                  padding: 0,
                  width: "100%"
                }}
                onClick={() => {
                  setVariablesVisible(true);
                  setStyleVisible(false);
                  setStepsVisible(false)

                }}
              >
                Variables
              </Typography.Title>
            </Col>
            <Col xs={24} sm={8} md={8}>
              <Typography.Title
                level={4}
                style={{
                  border: "solid",
                  cursor: "pointer",
                  borderColor: "lightBlue",
                }}
                onClick={() => {
                  setStyleVisible(true);
                  setVariablesVisible(false);
                  setStepsVisible(false);
                }}
              >
                Default Style Information
              </Typography.Title>
            </Col>
          </Row>
          {stepsVisible &&
            <div>
              {steps.map((step, index) => (
                <div key={index} className={styles.step}>
                  <Row gutter={16}>
                    <Typography.Text style={{ textAlign: "left" }}>
                      Step {index + 1}{" "}
                    </Typography.Text>
                    <Col span={24}>
                      <Button
                        type="text"
                        danger
                        icon={<MinusCircleOutlined />}
                        onClick={() => handleRemoveStep(index)}
                        style={{ float: "right" }}
                      >
                        Remove Step
                      </Button>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Key"
                        name={["steps", index, "key"]}
                        rules={[{ required: true, message: "Key is required" }]}
                      >
                        <Input placeholder="Key" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Title"
                        name={["steps", index, "title"]}
                        rules={[{ required: true, message: "Title is required" }]}
                      >
                        <Input placeholder="Title" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Order"
                        name={["steps", index, "order"]}
                        rules={[{ required: true, message: "Order is required" }]}
                      >
                        <Input type="number" placeholder="Order" min={1} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Persona"
                        name={["steps", index, "persona"]}
                        rules={[{ required: true, message: "Persona is required" }]}
                      >
                        <Input placeholder="Persona" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Task"
                        name={["steps", index, "task"]}
                        rules={[{ required: true, message: "Task is required" }]}
                      >
                        <TextArea placeholder="Task" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Input"
                        name={["steps", index, "input"]}
                        rules={[{ required: true, message: "Input is required" }]}
                      >
                        <TextArea placeholder="Input" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Format" name={["steps", index, "format"]}>
                        <Input placeholder="Format" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Active" name={["steps", index, "active"]}>
                        <Select placeholder="Active" defaultValue={true}>
                          <Option value={true}>True</Option>
                          <Option value={false}>False</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="AI Checker"
                        name={["steps", index, "aiChecker"]}
                      >
                        <Input placeholder="AI Checker" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="AI Checker Instructions"
                        name={["steps", index, "aiCheckerInstructions"]}
                      >
                        <Input placeholder="AI Checker Instructions" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="AI Checker Threshold"
                        name={["steps", index, "aiCheckerThreshold"]}
                      >
                        <Input type="number" placeholder="AI Checker Threshold" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Human Checker Style"
                        name={["steps", index, "humanCheckerStyle"]}
                      >
                        <Select
                          placeholder="Human Checker Style"
                          defaultValue="never"
                        >
                          <Option value="never">Never</Option>
                          <Option value="always">Always</Option>
                          <Option value="threshold">Threshold</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Human Checker Prompt"
                        name={["steps", index, "humanCheckerPrompt"]}
                      >
                        <Input placeholder="Human Checker Prompt" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Human Checker Threshold"
                        name={["steps", index, "humanCheckerThreshold"]}
                      >
                        <Input
                          type="number"
                          placeholder="Human Checker Threshold"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
              <Button
                type="dashed"
                onClick={handleAddStep}
                style={{ width: "100%", marginBottom: "20px" }}
                icon={<PlusOutlined style={{ color: "limegreen" }} />}
              >
                Add Step
              </Button>
            </div>
          }

          {variablesVisible && (
            <div style={{ marginBottom: "5px" }}>


              {/* TO DO display example json that can be copied */}
              <Typography.Text type="secondary" style={{ display: "block", marginTop: "8px", color: "limegreen" }}>

                Variables JSON String Format Example:

              </Typography.Text>
              <div style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "4px", position: "relative" }}>

                <pre style={{ color: "red" }}>

                  {`{
   "variables":[
      {
         "name":"variablename1",
         "type":"variabletype",
         "value":{
            "content":"defaultvalue"
         }
      }
   ]
}`}
                </pre>
                {copied ? <CheckOutlined style={{ position: "absolute", top: "10px", right: "10px", fontSize: "16px", color: "limegreen" }} /> :
                  <CopyOutlined
                    style={{ position: "absolute", top: "10px", right: "10px", fontSize: "16px", cursor: "pointer", color: "#1890ff" }}
                    onClick={() => {
                      navigator.clipboard.writeText(`{
  "variables": [
    {
      "name": "variablename1",
      "type": "variabletype",
      "value": {
        "content": "defaultvalue"
      }
    }
  ]
}`);
                      setCopied(true);
                      setTimeout(() => setCopied(false), 1500)
                    }} />
                }

              </div>
              <Form.Item label="Variables JSON String">
                <TextArea
                  label="Variables"
                  value={variables}
                  onChange={(e) => handleVariableChange(e.target.value)}
                  placeholder={`Enter variables JSON String`}
                  style={{ borderColor: "black" }}
                  rows={3}
                />

              </Form.Item>
            </div>
          )}
          {styleVisible && (

            <Form.Item label="Style Information">
              <TextArea
                value={styles}
                onChange={(e) => handleStyleChange(e.target.value)}
                placeholder={`Enter Styles Information`}
                rows={3}
              />
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default AddPromptletModal;
