import validator from "validator";
import { updatePromptlet } from "../../Redux/Features/Promptlet/updatePromptletSlice";
import { getLibrary } from "../../Redux/Features/Promptlet/getLibrarySlice";
import { toastError, toastSuccess } from "../toast";

const handleUpdatePromptlet = async (
  promptletToEditModal,
  setClientErrors,
  dispatch,
  setPromptletToEditModal
) => {
  let isErr = false;
  let errors = {};

  if (validator.isEmpty(promptletToEditModal?.title)) {
    isErr = true;
    errors.title = "Title is required";
  }

  if (promptletToEditModal?.steps?.length === 0) {
    isErr = true;
    errors.steps = "Steps is required";
  }

  if (isErr) {
    isErr = false;
    setClientErrors(errors);
  } else {
    isErr = false;
    setClientErrors({});
    dispatch(
      updatePromptlet({
        data: {
          title: promptletToEditModal?.title,
          steps: promptletToEditModal?.steps,
          visibility: promptletToEditModal?.visibility,
          variables: promptletToEditModal?.variables,
        },
        id: promptletToEditModal?.id,
        onSuccess: (message) => {
          dispatch(getLibrary());
          setPromptletToEditModal({
            open: false,
            copy:false,
            id: "",
            title: "",
            steps: [],
            visibility: "",
            variables:"",
          });
          toastSuccess(message);
        },
        onError: (message) => {
          setPromptletToEditModal({
            open: false,
            copy:false,
            id: "",
            title: "",
            steps: [],
            visibility: "",
            variables:"",
          });
          toastError(message);
        },
      })
    );
  }
};

export default handleUpdatePromptlet;
