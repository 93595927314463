import React from "react";
import { Col, Row, Button } from "antd";
import handleDebug from "../utils/problemConversation/Actions/handleDebug";
import handleAcceptResponse from "../utils/problemConversation/Actions/handleAcceptResponse";
import handleEditResponse from "../utils/problemConversation/Actions/handleEdit";
import handleRejectResponse from "../utils/problemConversation/Actions/handleRejectResponse";
import handleRequeryResponse from "../utils/problemConversation/Actions/handleRequeryResponse";
import { getCookie } from "../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPromptletStep } from "../Redux/Features/Promptlet/getPromptletStepSlice";

const ActionButtons = ({
  item,
  isMdOrAbove,
  setOpenDebugModal,
  setStepToEditModal,
  editedResponse,
  setEditedResponse,
  setIsEditorModalVisible,
  chats,
  setIsChat,
  setChats,
  setIsTyping,
  setMessage,
  problemData,
  problemId,
  message,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading: acceptResponseLoading } = useSelector(
    (state) => state?.acceptResponse
  );
  const user = JSON.parse(getCookie("authUser") || "{}");
  const handleModifyPrompletStep = (promptletId, stepId) => {
    dispatch(
      getPromptletStep({
        promptletId,
        stepId,
        onSuccess: (step) => {
          setStepToEditModal((prev) => ({
            ...prev,
            promptletId: promptletId,
            open: true,
            step: step,
          }));
        },
      })
    );
  };
  return (
    <Row justify="space-between" align="middle" gutter={[8, 8]}>
      {!item?.isResume && item?.isMoreButtons && (
        <>
          <Col>
            <Button
              type="dashed"
              onClick={() =>
                handleAcceptResponse(
                  {
                    ...item?.data,
                  },
                  chats,
                  dispatch,
                  setChats,
                  setIsChat,
                  setMessage,
                  setIsTyping,
                  navigate,
                  problemData,
                  problemId,
                  message
                )
              }
              disabled={acceptResponseLoading}
              loading={acceptResponseLoading}
            >
              Accept
            </Button>
          </Col>

          <Col>
            <Button
              type="dashed"
              disabled={!item?.humanChecker}
              onClick={() => handleRejectResponse(setIsChat, setChats)}
            >
              Reject
            </Button>
          </Col>

          {!item?.data?.reject && (
            <Col>
              <Button
                type="dashed"
                onClick={() =>
                  handleRequeryResponse(
                    chats,
                    setChats,
                    setIsTyping,
                    dispatch,
                    navigate,
                    problemId,
                    problemData
                  )
                }
              >
                Requery
              </Button>
            </Col>
          )}
          <Col>
            <Button
              type="dashed"
              onClick={() =>
                handleEditResponse(
                  { ...item?.data },
                  editedResponse,
                  setEditedResponse,
                  setIsEditorModalVisible,
                  chats,
                  dispatch
                )
              }
            >
              Edit
            </Button>
          </Col>
        </>
      )}
      {(user?.debugLevel !== "none" || user?.userType === "admin") && (
        <Row style={{ gap: "8px" }}>
          <Col>
            <Button
              type="dashed"
              className="debug-hidden"
              style={{
                marginLeft: "auto",
                display: isMdOrAbove ? "none" : "block",
                fontSize: "14px",
              }}
              onClick={() =>
                handleModifyPrompletStep(
                  item?.data?.library?._id,
                  item?.data?.library?.stepId
                )
              }
            >
              Modify Prompt
            </Button>
          </Col>
          <Col>
            <Button
              type="dashed"
              className="debug-hidden"
              style={{
                color: "red",
                marginLeft: "auto",
                display: isMdOrAbove ? "none" : "block",
                fontSize: "14px",
              }}
              onClick={() => {
                handleDebug(item?.data?.library?.stepId, dispatch);
                setOpenDebugModal(true);
              }}
            >
              Debug
            </Button>
          </Col>
        </Row>
      )}
    </Row>
  );
};

export default ActionButtons;
