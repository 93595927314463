import { formatDateToStandard, getCookie } from "../../utils/helper";
import { Button, Space, Tooltip } from "antd";
import styles from "./Home.module.scss";
import handleStepsData from "../../utils/home/handleStepsData";
import handleDeleteProblem from "../../utils/home/handleDeleteProblem";
import { restartProblem } from "../../Redux/Features/Problem/restartProblemSlice";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { addConversation } from "../../Redux/Features/Conversation/addConversationSlice";
import { useNavigate } from "react-router-dom";
const DataSource = (
  problemsData,
  getLibraryData,
  setEditProblemModal,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const handleStart = (item) => {
    const conversationId = uuidv4();
    const userId = getCookie("userId");
    localStorage.setItem("conversationId", conversationId);
    dispatch(
      addConversation({
        conversationId,
        userId,
        problemId: item?._id,
        onSuccess: () => {
          navigate(`/chat/${item?._id}`);
        },
      })
    );
  };
  const handleRestart = (item) => {
    const conversationId = uuidv4();
    const userId = getCookie("userId");
    localStorage.setItem("conversationId", conversationId);
    dispatch(
      addConversation({
        conversationId,
        userId,
        problemId: item?._id,
        onSuccess: () => {
          dispatch(
            restartProblem({
              id: item?._id,
              onSuccess: () => {
                navigate(`/chat/${item?._id}`);
              },
            })
          );
        },
      })
    );
  };

  return problemsData?.map((item, i) => ({
    key: i,
    date: (
      <p className={styles.paragraph1}>
        {formatDateToStandard(item?.created_at)}
      </p>
    ),
    title: <p className={styles.paragraph2}>{item?.title}</p>,
    description: (
      <p className={styles.paragraph3}>
        <Tooltip title={item?.description}>{`${item?.description?.slice(
          0,
          50
        )}${item?.description?.length > 78 ? "..." : ""}`}</Tooltip>
      </p>
    ),
    library: (
      <p className={styles.paragraph3}>
        <Tooltip title={handleStepsData(item?.library, getLibraryData)}>
          {handleStepsData(item?.library, getLibraryData)?.slice(0, 30) +
            "  ..."}
        </Tooltip>
      </p>
    ),
    language: <p className={styles.paragraph2}>{item?.language}</p>,

    action: (
      <Space>
        <Button
          style={{ color: "red" }}
          onClick={() => handleDeleteProblem(item?._id, dispatch)}
        >
          Delete
        </Button>

        {/* Edit Problem */}

        <Button
          type="primary"
          size="small"
          onClick={() => {
            setEditProblemModal({
              open: true,
              id: item?._id,
              title: item?.title,
              description: item?.description,
              library: item?.library,
              language: item?.language,
              variables:item?.variables,
              styleInformation:item?.styleInformation
            });
          }}
        >
          Edit
        </Button>

        {/* Start coversation */}

        {Number(item?.stepCount) === 0 && (
          <Button type="primary" size="small" onClick={() => handleStart(item)}>
            Start
          </Button>
        )}

        {/* restart the conversation */}

        {Number(item?.stepCount) > 0 && (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              handleRestart(item);
            }}
          >
            Restart
          </Button>
        )}
        {/* view conversation */}

        {Number(item?.stepCount) > 0 &&
          Number(item?.stepCount) <= Number(item?.maxCount) && (
            <Button
              type="primary"
              size="small"
              onClick={() => navigate(`/chat/${item?._id}`)}
            >
              View
            </Button>
          )}
      </Space>
    ),
  }));
};

export default DataSource;
