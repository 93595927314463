import validator from "validator";
import { addPromptlet } from "../../Redux/Features/Promptlet/addPromptletSlice";
import { getLibrary } from "../../Redux/Features/Promptlet/getLibrarySlice";

const saveAsNewPromptlet = async (
  promptlet,
  setClientErrors,
  dispatch,
  setPromptlet,
) => {
  let isErr = false;
  let errors = {};
  if (validator.isEmpty(promptlet?.title)) {
    isErr = true;
    errors.title = "Title is required";
  }
  if (promptlet?.steps?.length === 0) {
    isErr = true;
    errors.steps = "Steps is required";
  }

  if (isErr) {
    isErr = false;
    setClientErrors(errors);
  } else {
    isErr = false;
    setClientErrors({});
    dispatch(
      addPromptlet({
        data: {
          title: promptlet?.title,
          steps: promptlet?.steps,
          visibility:promptlet?.visibility,
          variables: promptlet?.variables
        },
        onSuccess: () => {
          setPromptlet({
            open:false,
            copy:false,
            title: "",
            steps: [],
            visibility:"sharedUnlocked",
            variables:""
          });
          dispatch(getLibrary());
        },
      })
    );
  }
};

export default saveAsNewPromptlet;
