import { formatDateToStandard, getCookie } from "../../utils/helper";
import { Button, Space } from "antd";
import styles from "./Promptlets.module.scss";
import handleDeletePromptlet from "../../utils/promptlet/handleDeletePromplet";
import { useDispatch, useSelector } from "react-redux";

const DataSource = (
  promptlets,
  setPromptletToEditModal,
  setViewPrompletModal
) => {
  const dispatch = useDispatch();
  const authUser = JSON.parse(getCookie("authUser") || "{}");
  const { isLoading } = useSelector((state) => state.deleteUser);
  return (
    promptlets.length > 0 &&
    promptlets?.map((item, i) => {
      const isOwner = item?.user?._id === authUser._id;
      const isAdmin = authUser.userType === "admin";
      const isPromptletSharedUnlocked = item?.visibility === "sharedUnlocked";
      const isPromptletPrivate = item?.visibility === "private";

      return {
        key: i,
        date: (
          <p className={styles.paragraph1}>
            {formatDateToStandard(item?.created_at)}
          </p>
        ),
        title: <p className={styles.paragraph2}>{item?.name}</p>,
        name: <p className={styles.paragraph2}>{item?.user?.name}</p>,
        visibility: item?.visibility,
        visibilityElement: (
          <p className={styles.paragraph2}>{item?.visibility}</p>
        ),
        action: (
          <Space>
            <Button
              style={{ color: "red" }}
              disabled={
              !(isOwner && isPromptletPrivate) && !(isAdmin && isPromptletPrivate) || isLoading
            }
              loading={isLoading}
              onClick={() => handleDeletePromptlet(item?._id, dispatch)}
            >
              Delete
            </Button>
            <Button
              style={{ color: "limegreen" }}
              disabled={!(isOwner || isPromptletSharedUnlocked)}
              onClick={() => {
                setPromptletToEditModal({
                  open: true,
                  copy:true,
                  id: item?._id,
                  title: "COPY of "+ item?.name,
                  steps: item?.steps,
                  visibility: item?.visibility,
                  variables: item?.variables,
                });
              }}
            >
              Copy 
            </Button>

            {/* Edit Promptlet */}
            <Button
              type="primary"
              size="small"
              disabled={!isOwner && !isAdmin}
              onClick={() => {
                setPromptletToEditModal({
                  open: true,
                  copy:false,
                  id: item?._id,
                  title: item?.name,
                  steps: item?.steps,
                  visibility: item?.visibility,
                  variables: item?.variables,
                });
              }}
            >
              Edit
            </Button>

            {/* View Promptlet */}
            <Button
              type="primary"
              size="small"
              disabled={
                item?.visibility === "sharedLocked" && !isAdmin && !isOwner
              }
              onClick={() => {
                setViewPrompletModal({
                  open: true,
                  id: item?._id,
                  title: item?.name,
                  steps: item?.steps,
                  visibility: item?.visibility,
                  variables: item?.variables,
                });
              }}
            >
              View
            </Button>
          </Space>
        ),
      };
    })
  );
};

export default DataSource;
