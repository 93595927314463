import React, { useState } from "react";
import { Col, Form, Input, Modal, Row, Select, Typography } from "antd";
import styles from "../../Pages/Home/Home.module.scss";
import handleUpdateProblem from "../../utils/home/handleUpdateProblem";
import handleStepsData from "../../utils/home/handleStepsData";
import { useDispatch } from "react-redux";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option, OptGroup } = Select;

const EditProblemModal = ({
  editProblemModal,
  setEditProblemModal,
  updateProblemLoading,
  setClientErrors,
  clientErrors,
  promptlets,
}) => {
  const dispatch = useDispatch();
  const [editProblemVisible, setEditProblemVisible] = useState(true);
  const [styleVisible, setStyleVisible] = useState(false);
  const [variableVisible, setVariableVisible] = useState(false);
  const [copied, setCopied] = useState(false);
  // List of languages supported by ChatGPT
  const supportedLanguages = [
    "English", "Spanish", "French", "German", "Portuguese", "Italian", "Dutch",
    "Russian", "Chinese (Simplified)", "Chinese (Traditional)", "Japanese",
    "Korean", "Arabic", "Hindi", "Bengali", "Urdu", "Persian/Farsi", "Turkish",
    "Polish", "Swedish", "Danish", "Norwegian", "Finnish", "Greek", "Thai",
    "Hebrew", "Indonesian", "Vietnamese", "Malay", "Romanian", "Czech",
    "Hungarian", "Slovak", "Ukrainian", "Bulgarian", "Serbian", "Croatian"
  ];
  const handleCancel = () => {
    setEditProblemModal({
      open: false,
      id: "",
      title: "",
      description: "",
      library: "",
      language: "",
      variables: "",
      styleInformation: ""
    });
    setClientErrors({});
  };

  const handleOk = () => {
    handleUpdateProblem(
      editProblemModal,
      setClientErrors,
      dispatch,
      setEditProblemModal
    );
  };

  const stepsData = handleStepsData(null, promptlets);

  return (
    <Modal
      centered
      open={editProblemModal?.open}
      onCancel={handleCancel}
      onOk={handleOk}
      okText="Update"
      confirmLoading={updateProblemLoading}
      width={900}
    >
      <div className={styles.edit_problem_container}>
        <Row gutter={[0, 0]} >
          <Col xs={24} sm={8} md={4}>
            <Typography.Title
              level={4}
              style={{
                border: "solid",
                borderColor: "lightBlue",
                cursor: "pointer",
                margin: 0,
                padding: 0,
                width: "100%"
              }}
              onClick={() => {
                setEditProblemVisible(true);
                setStyleVisible(false);
                setVariableVisible(false);
              }}
            >
              Edit Problem
            </Typography.Title>
          </Col>
          <Col xs={24} sm={8} md={3}>
            <Typography.Title
              level={4}
              style={{
                border: "solid",
                borderColor: "lightBlue",
                cursor: "pointer",
                margin: 0,
                padding: 0,
                width: "100%"

              }}
              onClick={() => {
                setStyleVisible(true);
                setVariableVisible(false);
                setEditProblemVisible(false);
              }}
            >
              Styles
            </Typography.Title>
          </Col>
          <Col xs={24} sm={8} md={3} >
            <Typography.Title
              level={4}
              style={{
                border: "solid",
                borderColor: "lightBlue",
                cursor: "pointer",
              }}
              onClick={() => {
                setVariableVisible(true);
                setStyleVisible(false);
                setEditProblemVisible(false);
              }
              }
            >
              Variables
            </Typography.Title>
          </Col>
        </Row>
        {editProblemVisible &&
          <Form layout="vertical">
            <Form.Item
              label="Problem Title"
              validateStatus={clientErrors?.title ? "error" : ""}
              help={clientErrors?.title}
            >
              <Input
                placeholder="Problem Title"
                size="large"
                value={editProblemModal?.title}
                onChange={(e) =>
                  setEditProblemModal({
                    ...editProblemModal,
                    title: e.target.value,
                  })
                }
              />
            </Form.Item>

            <Form.Item
              label="Problem Description"
              validateStatus={clientErrors?.description ? "error" : ""}
              help={clientErrors?.description}
            >
              <TextArea
                placeholder="Problem Description"
                size="large"
                rows={5}
                value={editProblemModal?.description}
                onChange={(e) =>
                  setEditProblemModal({
                    ...editProblemModal,
                    description: e.target.value,
                  })
                }
              />
            </Form.Item>

            <Form.Item
              label="Library"
              validateStatus={clientErrors?.library ? "error" : ""}
              help={clientErrors?.library}
            >
              <Select
                size="large"
                style={{ width: "100%" }}
                placeholder="Choose Library"
                value={editProblemModal?.library || undefined}
                onChange={(value) =>
                  setEditProblemModal({ ...editProblemModal, library: value })
                }
              >
                <OptGroup label="My Promptlets">
                  {stepsData?.myPromptlets?.map((item) => (
                    <Option key={item.value} value={item.value}>
                      <span>{item.label}</span>

                      <span
                        style={{
                          color: "#8c8c8c",
                          fontStyle: "italic",
                          marginLeft: 8,
                        }}
                      >
                        {item.author}
                      </span>
                    </Option>
                  ))}
                </OptGroup>
                <OptGroup label="Shared Promptlets">
                  {stepsData?.communityPromptlets?.map((item) => (
                    <Option key={item.value} value={item.value}>
                      <span>{item.label}</span>
                      <span
                        style={{
                          color: "#8c8c8c",
                          fontStyle: "italic",
                          marginLeft: 8,
                        }}
                      >
                        {item.author}
                      </span>
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </Form.Item>

            <Form.Item label="Language">
              <Select
                size="large"
                style={{ width: "50%", display: "flex" }}
                placeholder="Select language"
                value={editProblemModal?.language || undefined}
                onChange={(value) => setEditProblemModal({ ...editProblemModal, language: value })}>
                {supportedLanguages?.map((language) => (
                  <Option key={language} value={language}>
                    {language}

                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        }
        {variableVisible && (
          <div style={{ marginBottom: "5px" }}>
            <Typography.Text type="secondary" style={{ display: "block", marginTop: "8px", color: "limegreen" }}>
              Variables JSON String Format Example:
            </Typography.Text>
            <div style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "4px", position: "relative" }}>
              <pre style={{ color: "red" }}>

                {`{
   "variables":[
      {
         "name":"variablename1",
         "type":"variabletype",
         "value":{
            "content":"defaultvalue"
         }
      }
   ]
}`}
              </pre>
              {copied ? <CheckOutlined style={{ position: "absolute", top: "10px", right: "10px", fontSize: "16px", color: "limegreen" }} /> :
                <CopyOutlined
                  style={{ position: "absolute", top: "10px", right: "10px", fontSize: "16px", cursor: "pointer", color: "#1890ff" }}
                  onClick={() => {
                    navigator.clipboard.writeText(`{
  "variables": [
    {
      "name": "variablename1",
      "type": "variabletype",
      "value": {
        "content": "defaultvalue"
      }
    }
  ]
}`);
                    setCopied(true);
                    setTimeout(() => setCopied(false), 1500);
                  }} />}
            </div>
            <TextArea
              value={editProblemModal?.variables}
              onChange={(e) => setEditProblemModal({ ...editProblemModal, variables: e.target.value })}
              placeholder={`Enter variables JSON String`}
              style={{ borderColor: "lightBlue" }}
              rows={3}
            />


          </div>
        )}
        {styleVisible && (
          <TextArea
            value={editProblemModal?.styleInformation}
            onChange={(e) => setEditProblemModal({ ...editProblemModal, styleInformation: e.target.value })}
            placeholder={`Enter Style Informations`}
            rows={3}
            style={{ borderColor: "lightBlue" }}
          />

        )}
      </div>
    </Modal>
  );
};

export default EditProblemModal;
