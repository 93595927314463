import React from "react";
import { Modal, Button, Typography, Descriptions, Collapse, Card } from "antd";
import styles from "../../Pages/Home/Home.module.scss";
import TextArea from "antd/es/input/TextArea";

const { Panel } = Collapse;

const ViewPromptletModal = ({ viewPromptletModal, setViewPromptletModal }) => {
  return (
    <Modal
      centered
      open={viewPromptletModal?.open}
      onCancel={() =>
        setViewPromptletModal({
          open: false,
          id: "",
          title: "",
          steps: [],
          visibility: "",
          variables: "",
        })
      }
      footer={[
        <Button
          key="close"
          type="primary"
          onClick={() => setViewPromptletModal({ open: false })}
        >
          Close
        </Button>,
      ]}
      width={900}
    >
      <div className={styles.add_problem_container}>
        <Typography.Title level={3}>View Promptlet</Typography.Title>
        <Descriptions bordered column={2}>
          <Descriptions.Item label="Promptlet Title">
            {viewPromptletModal?.title}
          </Descriptions.Item>
          <Descriptions.Item label="Promptlet Visibility">
            {viewPromptletModal?.visibility}
          </Descriptions.Item>
        </Descriptions>

        <div className={styles.steps_container}>
          <Typography.Title level={4}>Steps</Typography.Title>
          <Collapse>
            {viewPromptletModal?.steps?.map((step, index) => (
              <Panel
                header={`Step ${index + 1} | ${step.key} | ${step?.title}`}
                key={index}
              >
                <Card>
                  <Descriptions bordered column={1}>
                    <Descriptions.Item label="Key">
                      {step.key}
                    </Descriptions.Item>
                    <Descriptions.Item label="Title">
                      {step.title}
                    </Descriptions.Item>
                    <Descriptions.Item label="Order">
                      {step.order}
                    </Descriptions.Item>
                    <Descriptions.Item label="Persona">
                      {step.persona}
                    </Descriptions.Item>
                    <Descriptions.Item label="Task">
                      {step.task}
                    </Descriptions.Item>
                    <Descriptions.Item label="Input">
                      {step.input}
                    </Descriptions.Item>
                    <Descriptions.Item label="Format">
                      {step.format}
                    </Descriptions.Item>
                    <Descriptions.Item label="Active">
                      {step.active ? "True" : "False"}
                    </Descriptions.Item>
                    <Descriptions.Item label="AI Checker">
                      {step.aiChecker}
                    </Descriptions.Item>
                    <Descriptions.Item label="AI Checker Instructions">
                      {step.aiCheckerInstructions}
                    </Descriptions.Item>
                    <Descriptions.Item label="AI Checker Threshold">
                      {step.aiCheckerThreshold}
                    </Descriptions.Item>
                    <Descriptions.Item label="Human Checker Style">
                      {step.humanCheckerStyle}
                    </Descriptions.Item>
                    <Descriptions.Item label="Human Checker Prompt">
                      {step.humanCheckerPrompt}
                    </Descriptions.Item>
                    <Descriptions.Item label="Human Checker Threshold">
                      {step.humanCheckerThreshold}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </Panel>
            ))}
          </Collapse>
          <Typography.Title level={4}>Variables</Typography.Title>
          <div>
            <TextArea value={viewPromptletModal.variables} rows={3}  />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewPromptletModal;
