import React from "react";
import { Input, Modal, Select, Typography, Form } from "antd";
import handleStepsData from "../../utils/home/handleStepsData";
import saveAsNewProblem from "../../utils/home/saveAsNewProblem";
import styles from "../../Pages/Home/Home.module.scss";

const { TextArea } = Input;
const { Option, OptGroup } = Select;

const AddProblemModal = ({
  addProblemModal,
  addProblemLoading,
  setProblem,
  setAddProblemModal,
  setEditProblemModal,
  clientErrors,
  promptlets,
  problem,
  setClientErrors,
  dispatch,
}) => {
  // List of languages supported by ChatGPT
  const supportedLanguages = [
    "English", "Spanish", "French", "German", "Portuguese", "Italian", "Dutch",
    "Russian", "Chinese (Simplified)", "Chinese (Traditional)", "Japanese",
    "Korean", "Arabic", "Hindi", "Bengali", "Urdu", "Persian/Farsi", "Turkish",
    "Polish", "Swedish", "Danish", "Norwegian", "Finnish", "Greek", "Thai",
    "Hebrew", "Indonesian", "Vietnamese", "Malay", "Romanian", "Czech",
    "Hungarian", "Slovak", "Ukrainian", "Bulgarian", "Serbian", "Croatian"
  ];
  const handleCancel = () => {
    setProblem({
      title: "",
      description: "",
      library: "",
      language: "",
    });
    setAddProblemModal(false);
    setClientErrors({});
  };

  const handleSave = () => {
    saveAsNewProblem(
      problem,
      setClientErrors,
      dispatch,
      setProblem,
      setAddProblemModal,
      setEditProblemModal
    );
  };

  const stepsData = handleStepsData(null, promptlets);

  return (
    <Modal
      centered
      open={addProblemModal}
      onCancel={handleCancel}
      onOk={handleSave}
      okText="Save"
      confirmLoading={addProblemLoading}
      width={900}
    >
      <div className={styles.add_problem_container}>
        <Form layout="vertical">
          <Typography.Title level={3}>Add Problem</Typography.Title>
          <Form.Item
            label="Problem Title"
            validateStatus={clientErrors?.title ? "error" : ""}
            help={clientErrors?.title}
          >
            <Input
              placeholder="Problem Title"
              size="large"
              value={problem?.title}
              onChange={(e) =>
                setProblem({ ...problem, title: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item
            label="Problem Description"
            validateStatus={clientErrors?.description ? "error" : ""}
            help={clientErrors?.description}
          >
            <TextArea
              placeholder="Problem Description"
              size="large"
              rows={5}
              value={problem?.description}
              onChange={(e) =>
                setProblem({ ...problem, description: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item
            label="Library"
            validateStatus={clientErrors?.library ? "error" : ""}
            help={clientErrors?.library}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
              placeholder="Choose Library"
              value={problem?.library || undefined}
              onChange={(value) => setProblem({ ...problem, library: value })}
            >
              <OptGroup label="My Promptlets">
                {stepsData?.myPromptlets?.map((item) => (
                  <Option key={item.value} value={item.value}>
                    <span>{item.label}</span>
                    <span
                      style={{
                        color: "#8c8c8c",
                        fontStyle: "italic",
                        marginLeft: 8,
                      }}
                    >
                      {item.author}
                    </span>
                  </Option>
                ))}
              </OptGroup>
              <OptGroup label="Shared Promptlets">
                {stepsData?.communityPromptlets?.map((item) => (
                  <Option key={item.value} value={item.value}>
                    <span>{item.label}</span>

                    <span
                      style={{
                        color: "#8c8c8c",
                        fontStyle: "italic",
                        marginLeft: 8,
                      }}
                    >
                      {item.author}
                    </span>
                  </Option>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item label="Language">
            <Select size="large"
              style={{ width: "50%", display: "flex" }}
              placeholder="Select Language"
              value={problem?.language || undefined}
              onChange={(value) => setProblem({ ...problem, language: value })}>

              {supportedLanguages?.map((language) => (
                <Option key={language} value={language}>
                  {language}

                </Option>
              ))}

            </Select>

          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddProblemModal;
