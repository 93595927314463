import React, { Fragment } from "react";
import { Typography, Button } from "antd";
import cc from "classnames";
import ActionButtons from "./actionButtons";
import styles from "../Pages/ProblemConversation/ProblemConversation.module.scss";
import RenderContent from "./renderContent";
import handleDebug from "../utils/problemConversation/Actions/handleDebug";
import { getCookie } from "../utils/helper";
import { useDispatch } from "react-redux";
import { getPromptletStep } from "../Redux/Features/Promptlet/getPromptletStepSlice";

const ChatItem = ({
  item,
  i,
  chats,
  isMdOrAbove,
  isSMOrBellow,
  isWritting,
  setIsWritting,
  isResume,
  setChats,
  setOpenDebugModal,
  setStepToEditModal,
  editedResponse,
  setEditedResponse,
  setIsEditorModalVisible,
  setIsChat,
  setIsTyping,
  setMessage,
  problemData,
  problemId,
  message,
}) => {
  const dispatch = useDispatch();
  const user = JSON.parse(getCookie("authUser") || "{}");
  const stepKey = item?.data?.step?.key;
  const isUser = item?.role?.toUpperCase() === "USER";
  const aiChecker = item?.data?.step?.aiChecker;
  const isChecking = item?.isAIChecking;
  const renderActionButtons = () => {
    if (isWritting) return null;
    return (
      <ActionButtons
        item={item}
        isMdOrAbove={isMdOrAbove}
        setOpenDebugModal={setOpenDebugModal}
        setStepToEditModal={setStepToEditModal}
        editedResponse={editedResponse}
        setEditedResponse={setEditedResponse}
        setIsEditorModalVisible={setIsEditorModalVisible}
        chats={chats}
        setIsChat={setIsChat}
        setIsTyping={setIsTyping}
        setMessage={setMessage}
        problemData={problemData}
        problemId={problemId}
        message={message}
        setChats={setChats}
      />
    );
  };

  const renderContent = () => {
    return (
      <RenderContent
        problemId={problemId}
        isResume={isResume}
        componentKey={stepKey}
        content={item?.data[stepKey]?.content}
        title={item?.data[stepKey]?.title}
        chats={chats}
        language={item?.data?.language}
        isWritting={isWritting}
        setIsWritting={setIsWritting}
        setChats={setChats}
      />
    );
  };
  const handleModifyPrompt = (promptletId, stepId) => {
    dispatch(
      getPromptletStep({
        promptletId,
        stepId,
        onSuccess: (step) => {
          setStepToEditModal((prev) => ({
            ...prev,
            promptletId: promptletId,
            open: true,
            step: step,
          }));
        },
      })
    );
  };
  const renderDebugButton = () => {
    if (isWritting) return null;

    return (
      <div
        style={{
          marginLeft: "auto",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Button
          type="dashed"
          style={{
            display: isSMOrBellow ? "none" : "block",
            fontSize: "14px",
          }}
          onClick={() =>
            handleModifyPrompt(
              item?.data?.library?._id,
              item?.data?.library?.stepId
            )
          }
        >
          Modify Prompt
        </Button>
        <Button
          type="dashed"
          style={{
            color: "red",
            display: isSMOrBellow ? "none" : "block",
            fontSize: "14px",
          }}
          onClick={() => {
            handleDebug(item?.data?.library?.stepId, dispatch);
            setOpenDebugModal(true);
          }}
          className="debug"
        >
          Debug
        </Button>
      </div>
    );
  };

  const renderContentAndActions = () => (
    <>
      {renderContent()}
      <div className={styles.button_container} key={i}>
        {renderActionButtons()}
        {(user?.debugLevel !== "none" || user?.userType === "admin") &&
          renderDebugButton()}
      </div>
    </>
  );

  return (
    <div className={cc(styles.chats, isUser && styles.chats_user)} key={i}>
      {item?.content ? (
        <Typography.Paragraph
          className={cc(styles.paragraph2, isUser && styles.colorBlue)}
        >
          {isUser ? item?.content : null}
        </Typography.Paragraph>
      ) : (
        <Fragment>
          {item?.data && (
            <>
              {!item?.isResume && aiChecker ? (
                isChecking ? (
                  <div className={styles.typing_container} key={stepKey}>
                    <Typography.Paragraph>AI checking...</Typography.Paragraph>
                  </div>
                ) : (
                  renderContentAndActions()
                )
              ) : (
                renderContentAndActions()
              )}
            </>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default ChatItem;
